import { validateNPI, validateZipCode } from '@myndshft/validation'

interface IConstraint {
	readonly validate: (value: string) => boolean
	readonly message: string
}

export const constraints: { [index: string]: IConstraint } = {
	NPI: {
		validate: validateNPI,
		message: 'The NPI that you have entered is invalid.',
	},
	ZipCode: {
		validate: validateZipCode,
		message: 'The zip code that you have entered is invalid.',
	},
}
