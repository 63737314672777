
import { defineComponent, ref, computed, watchEffect, onUnmounted } from 'vue'

import FuzzySearch from 'fuzzy-search'
import ListBox from '@/components/worklist/wizard/create/listbox.vue'
import store from '@/store/index'
import { IWorklistPayer } from '@server/api/models/fe/worklist'

export default defineComponent({
	name: 'payer-listbox',
	components: {
		[ListBox.name]: ListBox,
	},
	setup() {
		const sourceCopy = ref<IWorklistPayer[]>([])
		const selectablePayers = computed(() => {
			return sourceCopy.value.map(source => {
				return (
					(targetCopy.value.find(
						target => target.id === source.id
					) as IWorklistPayer) || source
				)
			})
		})
		const isLoadingPayers = ref(false)
		const targetCopy = ref<IWorklistPayer[]>([])
		const targetQuery = ref('')
		const targetDisplay = computed(() => {
			const searcher = new FuzzySearch(targetCopy.value, ['name'], {
				caseSensitive: false,
				sort: true,
			})

			return searcher.search(targetQuery.value)
		})
		const total = ref(0)
		const selectedTotal = ref(0)

		watchEffect(() => {
			sourceCopy.value = store.state.worklistCreateWizard.payers.payers
			targetCopy.value =
				store.state.worklistCreateWizard.worklist.parameters.payers
			isLoadingPayers.value = store.state.worklistCreateWizard.payers.isLoading
			total.value = store.state.worklistCreateWizard.payers.total
			selectedTotal.value =
				store.state.worklistCreateWizard.worklist.parameters.payers.length
		})

		onUnmounted(() => {
			onGetPayersByQuery('')
		})

		const onSelected = (record: IWorklistPayer) => {
			const item: IWorklistPayer = sourceCopy.value.find(
				source => source.id === record.id
			) as IWorklistPayer

			if (!item.selected) {
				item.selected = true
				// Push new target value
				store.commit('worklistCreateWizard/addParameterPayer', item)
			}
		}

		const onDeselected = (record: any) => {
			const index = targetCopy.value.findIndex(
				(targetRecord: any) => targetRecord.id === record.id
			)
			const item = targetCopy.value[index]
			item.selected = false

			store.commit('worklistCreateWizard/removeParameterPayer', index)
		}

		const onGetPayers = () => {
			store.dispatch('worklistCreateWizard/getPayers')
		}

		const onGetPayersByQuery = (query: string) => {
			store.dispatch('worklistCreateWizard/setPayersQuery', query)
			store.dispatch('worklistCreateWizard/resetPayers')
			store.dispatch('worklistCreateWizard/getPayers')
		}

		const onTargetQueryChange = (query: string) => {
			targetQuery.value = query
		}

		return {
			total,
			selectedTotal,
			selectablePayers,
			isLoadingPayers,
			targetDisplay,
			onSelected,
			onDeselected,
			onTargetQueryChange,
			onGetPayers,
			onGetPayersByQuery,
		}
	},
})
