
import { defineComponent } from 'vue'
import { useStore } from '@/utils/composables'

import SelectionSummary from '@/components/worklist/wizard/create/selection-summary.vue'
import {
	IWorklistPayer,
	IWorklistProcedureCode,
} from '@server/api/models/fe/worklist'

export default defineComponent({
	name: 'confirmation-summary',
	components: {
		SelectionSummary,
	},
	setup() {
		const store = useStore()

		const payers: IWorklistPayer[] =
			store.getters['worklistCreateWizard/getParameterPayers']

		const procedures: IWorklistProcedureCode[] =
			store.getters['worklistCreateWizard/getParameterProcedures']

		return {
			payers,
			procedures,
		}
	},
})
