
import { defineComponent, PropType } from 'vue'

import { MyndshftPanel } from '@myndshft/panel'
import store from '@/store/index'
import {
	IWorklistPayer,
	IWorklistProcedureCode,
} from '@server/api/models/fe/worklist'
import { IPayerPlan } from '@server/api/models/fe/payer'

export default defineComponent({
	name: 'selection-summary',
	components: {
		MyndshftPanel,
	},
	props: {
		payers: { type: Array as PropType<IWorklistPayer[]>, default: () => [] },
		procedures: {
			type: Array as PropType<IWorklistProcedureCode[]>,
			default: () => [],
		},
	},
	setup(props) {
		const onRemovePayer = (selectedPayer: IWorklistPayer) => {
			const index = props.payers.findIndex(
				payer => payer.id === selectedPayer.id
			)
			store.commit('worklistUploadWizard/removeParameterPayer', index)
		}

		const onRemovePlan = (
			selectedPlan: IPayerPlan,
			selectedPayer: IWorklistPayer
		) => {
			store.commit('worklistUploadWizard/removeParameterPlan', {
				payerId: selectedPayer.id,
				plan: selectedPlan,
			})

			if (!selectedPayer.plans.length) {
				onRemovePayer(selectedPayer)
			}
		}

		const onRemoveProcedure = (selectedProcedure: IWorklistProcedureCode) => {
			const index = props.procedures.indexOf(selectedProcedure)
			store.commit('worklistUploadWizard/removeParameterProcedure', index)
		}

		return {
			onRemovePayer,
			onRemovePlan,
			onRemoveProcedure,
		}
	},
})
