
import { defineComponent, ref, computed, watchEffect, PropType } from 'vue'

import FuzzySearch from 'fuzzy-search'
import store from '@/store/index'
import { IWorklistPayer } from '@server/api/models/fe/worklist'
import { IPayerPlan } from '@server/api/models/fe/payer'

export default defineComponent({
	name: 'plan-listbox',
	props: {
		payers: { type: Array as PropType<IWorklistPayer[]>, default: () => [] },
		plans: { type: Array as PropType<IPayerPlan[]>, default: () => [] },
		isLoading: {
			type: Boolean,
			default: false,
		},
	},
	setup(props) {
		const payerQuery = ref('')
		const payersDisplay = computed(() => {
			const searcher = new FuzzySearch(props.payers, ['name'], {
				caseSensitive: false,
				sort: true,
			})

			return searcher.search(payerQuery.value)
		})
		const isShowingAllPlans = ref(false)

		const currentPayer = ref<IWorklistPayer>({
			id: '',
			name: '',
			plans: [],
			paPlans: [],
			uniqueId: '',
			hasPaRecords: false,
		} as IWorklistPayer)
		const planQuery = ref<string>('')
		const planCopy = ref<IPayerPlan[]>(props.plans)
		watchEffect(() => {
			planCopy.value = props.plans
		})
		// planDisplay represents the list of plans that are selectable/deselectable
		// in the plan selection box
		const planDisplay = computed(() => {
			const canAddPlans = isShowingAllPlans.value
			// newPlans represents plans not in payers original paPlans array
			const newPlans = currentPayer.value?.plans?.filter(plan => {
				return (
					currentPayer.value?.paPlans?.findIndex(
						paPlan => paPlan.id === plan.id
					) === -1
				)
			})
			// availablePlans should always show paPlans + additional selected plans
			// that are not in the original paPlans array
			const availablePlans = [...currentPayer.value?.paPlans, ...newPlans]
			const sourceList =
				currentPayer.value.hasPaRecords && !canAddPlans
					? availablePlans
					: planCopy.value
			// sorting alphabetically
			sourceList.sort((a, b) => {
				if (a.name.toUpperCase() > b.name.toUpperCase()) {
					return 1
				}
				return -1
			})
			const searcher = new FuzzySearch(sourceList, ['name'], {
				caseSensitive: false,
				sort: true,
			})

			return searcher.search(planQuery.value)
		})

		const onSelectedPayer = (selectedPayer: IWorklistPayer) => {
			currentPayer.value = selectedPayer

			onClearPlanSearch()
		}

		const onSelectedPlan = (plan: IPayerPlan) => {
			if (isSelected(plan)) {
				// if the plan is already selected on that payer, remove it
				store.commit('worklistCreateWizard/removeParameterPlan', {
					payerId: currentPayer.value.id,
					plan,
				})
			} else {
				store.commit('worklistCreateWizard/addParameterPlan', {
					payerId: currentPayer.value.id,
					plan,
				})
			}
		}
		const toggleAllPlans = () => {
			isShowingAllPlans.value = !isShowingAllPlans.value
		}

		const isSelected = (plan: IPayerPlan): boolean => {
			const x = currentPayer.value.plans.findIndex(
				payerPlan => payerPlan.id === plan.id
			)
			return x >= 0
		}

		const getCurrentPlans = () => {
			return currentPayer.value.plans
		}

		const getAllPayerPlansCount = () => {
			let count = 0

			props.payers.forEach((payer: IWorklistPayer) => {
				count += payer.plans.length
			})

			return count
		}

		const onClearPayerSearch = () => {
			payerQuery.value = ''
		}

		const onClearPlanSearch = () => {
			planQuery.value = ''
		}

		return {
			onSelectedPayer,
			onSelectedPlan,
			onClearPayerSearch,
			onClearPlanSearch,
			currentPayer,
			getCurrentPlans,
			getAllPayerPlansCount,
			payerQuery,
			payersDisplay,
			planQuery,
			planDisplay,
			isSelected,
			isShowingAllPlans,
			toggleAllPlans,
		}
	},
})
