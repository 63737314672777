
import { defineComponent, ref, watchEffect, onMounted } from 'vue'

import PlanListbox from '@/components/worklist/wizard/create/plan-listbox.vue'

import { fetchPlans } from '@/service/shared.http'

import store from '@/store/index'
import { IWorklistPayer } from '@server/api/models/fe/worklist'
import { IPayerPlan } from '@server/api/models/fe/payer'

export default defineComponent({
	name: 'plan-select',
	components: {
		PlanListbox,
	},
	setup() {
		const payers = ref<IWorklistPayer[]>([])
		const plans = ref<IPayerPlan[]>([])
		const isLoadingPlans = ref(true)

		watchEffect(() => {
			payers.value = store.state.worklistCreateWizard.worklist.parameters.payers
		})

		onMounted(() => {
			fetchPlans().then(plansList => {
				plans.value = plansList
				isLoadingPlans.value = false
			})
		})

		return {
			payers,
			plans,
			isLoadingPlans,
		}
	},
})
