
import { defineComponent, SetupContext, ref } from 'vue'

import MyndshftInput from '@/components/form/input.vue'

export default defineComponent({
	name: 'listbox',
	components: {
		MyndshftInput,
	},
	props: {
		source: {
			type: Array,
			default: () => [],
		},
		filterOn: Array,
		title: String,
		total: Number,
		type: String,
		notFoundMessage: String,
		isLoading: {
			type: Boolean,
			default: false,
		},
		searchPlaceholder: {
			type: String,
			default: '',
		},
	},
	setup(props, context: SetupContext) {
		const query = ref('')

		const onSelected = (record: any) => {
			context.emit('onSelected', record)
		}

		function onScroll(e: any) {
			const listBox = e.target
			if (
				listBox.offsetHeight + listBox.scrollTop >= listBox.scrollHeight &&
				!props.isLoading
			) {
				context.emit('onScrollBottom')
			}
		}

		const onQueryComplete = () => {
			context.emit('onQueryComplete', query.value)
		}

		const onClearQuery = () => {
			query.value = ''
			context.emit('onQueryComplete', query.value)
		}

		return {
			query,
			onSelected,
			onScroll,
			onQueryComplete,
			onClearQuery,
		}
	},
})
