
import { defineComponent, PropType } from 'vue'
import { WizardStepState, WizardStep } from './types'
import MyndshftPanel from '@myndshft/panel'

export default defineComponent({
	name: 'wizard-steps',
	components: {
		[MyndshftPanel.name]: MyndshftPanel,
	},
	props: {
		id: String,
		steps: {
			type: Array as PropType<WizardStep[]>,
			default: () => [],
		},
	},
	setup(props) {
		const wizardId = props.id || Math.random().toString(36).substring(2, 12)
		function computeStepClass(state: WizardStepState) {
			return {
				'is-active': state === WizardStepState.ACTIVE,
				'is-completed': state === WizardStepState.COMPLETED,
			}
		}

		return {
			wizardId,
			computeStepClass,
			WizardStepState,
		}
	},
})
