export enum WizardStepState {
	DEFAULT,
	ACTIVE,
	COMPLETED,
}

export enum Conflict {
	NAME = 'Name',
	UNIQUE_NAME = 'Unique Name',
}

export interface WizardStep {
	label: string
	icon: string
	state?: WizardStepState
}
