
import { defineComponent } from 'vue'

export default defineComponent({
	name: 'myndshft-select',
	props: {
		disabled: {
			type: Boolean,
			default: false,
		},
		placeholder: {
			value: String,
			default: '',
		},
		options: Array,
		optionLabel: Function,
		search: {
			type: Function,
			default: () => {
				return
			},
		},
		label: {
			value: String,
			default: '',
		},
		value: [Object, Number, String, Array],
		isRequired: {
			value: Boolean,
			default: true,
		},
		multiple: {
			value: Boolean,
			default: false,
		},
	},
	setup(_, context) {
		const input = (event: any) => {
			context.emit('input', event)
		}
		return {
			input,
		}
	},
})
