
import {
	defineComponent,
	ref,
	computed,
	watch,
	onMounted,
	onUnmounted,
	watchEffect,
} from 'vue'

import FuzzySearch from 'fuzzy-search'
import store from '@/store/index'
import Listbox from '@/components/worklist/wizard/create/listbox.vue'
import MyndshftSelect from '@/components/form/select.vue'
import { fetchProcedureCategories } from '@/service/worklist.http'
import {
	IProcedureCategory,
	IWorklistProcedureCode,
} from '@server/api/models/fe/worklist'

export default defineComponent({
	name: 'code-listbox',
	props: {
		isLoading: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		[Listbox.name]: Listbox,
		[MyndshftSelect.name]: MyndshftSelect,
	},
	setup() {
		const categories = ref<IProcedureCategory[]>([])
		const selectedCategory = ref<IProcedureCategory | null>(null)
		const targetQuery = ref('')
		const sourceCopy = ref<IWorklistProcedureCode[]>([])
		const selectableCodes = computed(() => {
			return sourceCopy.value.map(source => {
				return (
					(targetCopy.value.find(
						target => target.code === source.code
					) as IWorklistProcedureCode) || source
				)
			})
		})
		const isLoadingProcedures = ref(false)
		const targetCopy = ref<IWorklistProcedureCode[]>([])
		const targetDisplay = computed(() => {
			const searcher = new FuzzySearch(
				targetCopy.value,
				['code', 'description'],
				{
					caseSensitive: false,
					sort: true,
				}
			)

			return searcher.search(targetQuery.value)
		})
		const total = ref(0)
		const selectedTotal = ref(0)

		watchEffect(() => {
			sourceCopy.value =
				store.state.worklistCreateWizard.procedureCodes.procedureCodes
			targetCopy.value =
				store.state.worklistCreateWizard.worklist.parameters.procedureCodes
			isLoadingProcedures.value =
				store.state.worklistCreateWizard.procedureCodes.isLoading
			total.value = store.state.worklistCreateWizard.procedureCodes.total
			selectedTotal.value =
				store.state.worklistCreateWizard.worklist.parameters.procedureCodes.length
		})

		onUnmounted(() => {
			onGetProceduresByQuery('')
		})

		onMounted(() => {
			fetchProcedureCategories().then(procedureCategories => {
				categories.value = procedureCategories.categories
			})
		})

		const onSelected = (procedure: IWorklistProcedureCode) => {
			const item: IWorklistProcedureCode = sourceCopy.value.find(
				source => source.code === procedure.code
			) as IWorklistProcedureCode

			if (!item.selected) {
				item.selected = true
				// Push new target value
				store.commit('worklistCreateWizard/addParameterProcedure', item)
			}
		}

		const onDeselected = (procedure: IWorklistProcedureCode) => {
			const index = targetCopy.value.findIndex(
				(targetRecord: any) => targetRecord.code === procedure.code
			)
			const item = targetCopy.value[index]
			item.selected = false

			store.commit('worklistCreateWizard/removeParameterProcedure', index)
		}

		const onGetProcedures = () => {
			store.dispatch('worklistCreateWizard/getProcedures')
		}

		const onGetProceduresByQuery = (query: string) => {
			store.dispatch('worklistCreateWizard/setProceduresQuery', query)
			store.dispatch('worklistCreateWizard/resetProcedures')
			store.dispatch('worklistCreateWizard/getProcedures')
		}

		watch(selectedCategory, (next, prev) => {
			if (next) {
				store.dispatch('worklistCreateWizard/setProceduresCategory', next.id)
				store.dispatch('worklistCreateWizard/resetProcedures')
				store.dispatch('worklistCreateWizard/getProcedures')
			} else if (next === null && prev !== undefined) {
				// only reset if removing category
				store.dispatch('worklistCreateWizard/setProceduresCategory', '')
				store.dispatch('worklistCreateWizard/resetProcedures')
				store.dispatch('worklistCreateWizard/getProcedures')
			}
		})

		const onTargetQueryChange = (query: string) => {
			targetQuery.value = query
		}

		return {
			total,
			selectedTotal,
			selectableCodes,
			targetDisplay,
			isLoadingProcedures,
			categories,
			selectedCategory,

			onSelected,
			onDeselected,
			onTargetQueryChange,
			onGetProcedures,
			onGetProceduresByQuery,
		}
	},
})
