
import {
	defineComponent,
	computed,
	onBeforeMount,
	onBeforeUnmount,
	ref,
	watch,
	onMounted,
} from 'vue'
import { MachineEvent, NULL_EVENT } from '@/store/utils/machine'
import { useStore } from '@/utils/composables'

import { useWizard } from '@/components/shared/wizard/steps'
import { WizardStep } from '@/components/shared/wizard/types'
import WizardSteps from '@/components/shared/wizard/steps.vue'

import PayerListbox from '@/components/worklist/wizard/create/payer-listbox.vue'
import CodeListbox from '@/components/worklist/wizard/create/code-listbox.vue'
import PlanSelect from '@/components/worklist/wizard/create/plan-select.vue'
import ConfirmationSummary from '@/components/worklist/wizard/create/confirmation-summary.vue'
import InputComponent from '@/components/form/input.vue'

import { useVuexMachine } from '@/store/utils/machine'
import worklistWizardMachine, {
	WorklistWizardStep,
} from '@/store/worklist-create-wizard/machine'

import {
	IWorklistPayer,
	IWorklistProcedureCode,
	WorklistInclude,
} from '@server/api/models/fe/worklist'

const wizardSteps: WizardStep[] = [
	{
		label: 'Select Payers',
		icon: 'hospital',
	},
	{
		label: 'Select Plans',
		icon: 'heartbeat',
	},
	{
		label: 'Select Codes',
		icon: 'stethoscope',
	},
	{
		label: 'Confirm',
		icon: 'check',
	},
]

export default defineComponent({
	name: 'worklist-create-wizard',
	components: {
		[WizardSteps.name]: WizardSteps,
		[PayerListbox.name]: PayerListbox,
		[PlanSelect.name]: PlanSelect,
		[CodeListbox.name]: CodeListbox,
		[ConfirmationSummary.name]: ConfirmationSummary,
		[InputComponent.name]: InputComponent,
	},
	setup() {
		const store = useStore()
		const { steps, currentStep } = useWizard(wizardSteps)
		const { state, transition } = useVuexMachine(
			store,
			worklistWizardMachine,
			'worklistCreateWizard'
		)
		const wizardStep = computed(
			() => store.getters['worklistCreateWizard/wizardStep'] as number
		)
		const selectedPayers = computed<IWorklistPayer[]>(() => {
			return store.state.worklistCreateWizard.worklist.parameters.payers
		})
		const selectedProcedureCodes = computed<IWorklistProcedureCode[]>(() => {
			return store.state.worklistCreateWizard.worklist.parameters.procedureCodes
		})

		const canAdvance = computed(() => {
			switch (wizardStep.value) {
				case 0:
					return hasSelectedPayers.value
				case 1:
					return hasSelectedPlans.value
				case 2:
					return hasSelectedProcedureCodes.value
				case 3:
					return (
						hasSelectedPayers.value &&
						hasSelectedPlans.value &&
						hasSelectedProcedureCodes.value
					)
				default:
					return false
			}
		})

		const hasSelectedPayers = computed(() => {
			return !!selectedPayers.value.length
		})

		const hasSelectedPlans = computed(() => {
			return (
				hasSelectedPayers.value &&
				selectedPayers.value.every(worklistPayer => worklistPayer.plans.length)
			)
		})

		const hasSelectedProcedureCodes = computed(() => {
			return !!selectedProcedureCodes.value.length
		})

		const worklistName = ref('')
		const worklistInclude = WorklistInclude
		const include = ref(WorklistInclude.EVERYTHING)
		const includeActiveCheckbox = ref<HTMLInputElement>()

		onBeforeMount(() => {
			transition(NULL_EVENT)
		})

		onBeforeUnmount(() => {
			store.dispatch('worklistCreateWizard/reset')
		})

		onMounted(() => {
			store.commit(
				'worklistCreateWizard/setInclude',
				WorklistInclude.EVERYTHING
			)
		})

		watch(
			() => wizardStep.value,
			wStep => {
				currentStep.value = wStep
			}
		)

		watch(worklistName, curr => {
			store.commit('worklistCreateWizard/setName', curr)
		})

		function isOnStep(step: WorklistWizardStep): boolean {
			return state.value.matches(`wizard.steps.${step}`)
		}

		// State Machine Events
		const PREV: MachineEvent = { type: 'PREV' }
		const NEXT: MachineEvent = { type: 'NEXT' }

		const onIncludeExisting = (event: any) => {
			if (event.target.checked && includeActiveCheckbox.value) {
				includeActiveCheckbox.value.checked = false
			}

			include.value = event.target.checked
				? WorklistInclude.NEW_ONLY
				: WorklistInclude.EVERYTHING
			store.commit('worklistCreateWizard/setInclude', include.value)
		}

		const onExcludeDeactivated = (event: any) => {
			include.value = event.target.checked
				? WorklistInclude.ACTIVE_ONLY
				: WorklistInclude.EVERYTHING
			store.commit('worklistCreateWizard/setInclude', include.value)
		}

		return {
			steps,
			state,
			wizardStep,
			transition,
			isOnStep,

			PREV,
			NEXT,
			canAdvance,

			worklistWizardMachine,
			WorklistWizardStep,

			worklistName,
			selectedPayers,
			include,
			worklistInclude,
			includeActiveCheckbox,

			onIncludeExisting,
			onExcludeDeactivated,
		}
	},
})
