
import { defineComponent, SetupContext, onMounted, ref } from 'vue'

import { constraints } from './validation'

export default defineComponent({
	name: 'myndshft-input',
	props: {
		id: String,
		label: String,
		value: String,
		disabled: Boolean,
		required: Boolean,
		placeholder: String,
		type: String,
		step: String,
		validateNPI: Boolean,
		validateZipCode: Boolean,
		maxlength: Number,
		nospace: Boolean,
		errors: Array,
		min: String,
		max: String,
	},
	setup(props, context: SetupContext) {
		const inputId = props.id || Math.random().toString(36).substr(2, 10)

		function onInput(event: any): void {
			context.emit('input', event.target.value)
		}

		function onFocus(): void {
			context.emit('focus')
		}

		function onBlur(): void {
			context.emit('blur')
		}

		function onSpaceKeyDown(event: KeyboardEvent): void {
			if (props.nospace) {
				event.preventDefault()
			}
		}

		function onEnterKeyDown(): void {
			context.emit('enter')
		}

		function onEscapeKeyDown(): void {
			context.emit('escape')
		}

		function onKeyPress(event: KeyboardEvent): void {
			context.emit('keypress', event)
		}

		// Register validations
		const inputElement = ref(null)
		onMounted(() => {
			Object.keys(constraints).forEach(key => {
				if (props[`validate${key}`]) {
					const { validate, message } = constraints[key]
					const element = inputElement.value as any
					element.addEventListener('input', () => {
						if (!validate(element.value)) {
							element.setCustomValidity(message)
						} else {
							element.setCustomValidity('')
						}
						element.checkValidity()
					})
				}
			})
		})

		return {
			inputId,
			onInput,
			onFocus,
			onBlur,
			onSpaceKeyDown,
			onEnterKeyDown,
			onEscapeKeyDown,
			onKeyPress,
			inputElement,
		}
	},
})
