import { ref, computed } from 'vue'
import { WizardStep, WizardStepState } from './types'

function stepTransitioner(steps: WizardStep[]) {
	return (step: number): WizardStep[] => {
		const newSteps = JSON.parse(JSON.stringify(steps)) as WizardStep[]
		newSteps.forEach((s, i) => {
			if (i < step) {
				s.state = WizardStepState.COMPLETED
			} else if (i > step) {
				s.state = WizardStepState.DEFAULT
			} else {
				s.state = WizardStepState.ACTIVE
			}
		})
		return newSteps
	}
}

export function useWizard(wizardSteps: WizardStep[]) {
	const currentStep = ref(0)
	const transitionTo = stepTransitioner(wizardSteps)
	const steps = computed(() => transitionTo(currentStep.value))

	return {
		steps,
		currentStep,
	}
}
